function renderButton() {
    gapi.signin2.render('google-signin2', {
        'scope': 'profile email',
        'height': 56,
        'longtitle': true,
        'onSuccess': onGoogleSignIn
    });
}
function onGoogleSignIn(googleUser) {
    var profile = googleUser.getBasicProfile();
    var idToken = googleUser.getAuthResponse().id_token;
    var email = profile.getEmail();
    var firstname = profile.getGivenName();
    var lastname = profile.getFamilyName();
    if (idToken && email) {
        jQuery.ajax({
            url: "externallogin",
            type: "POST",
            data: { email: email, idToken: idToken, firstname: firstname, lastname: lastname, login_type: 'google' },
            statusCode: {
                400: function (error) {
                    console.log(error.responseText);
                    googleSignOut();
                },
                200: function (response) {
                    googleSignOut();
                    window.location.replace("//" + response);
                }

            }
        });
    } else {
        googleSignOut();
    }
}
function googleSignOut() {
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () { });
}

function checkLoginState() {
    FB.login(function (response) {
        console.log(response);
        if (response.status == "connected") {
            onFacebookSignIn(response.authResponse);
        }
    }, { scope: 'email', config_id: '545645827376506' });
}

function onFacebookSignIn(authResponse) {
    if (authResponse && authResponse.accessToken) {
        jQuery.ajax({
            url: "externallogin",
            type: "POST",
            data: { access_token: authResponse.accessToken, login_type: 'facebook' },
            statusCode: {
                404: function () {
                    facebookLogOut();

                },
                400: function () {
                    facebookLogOut();
                },
                200: function (response) {
                    facebookLogOut();
                    window.location.replace("//" + response);
                }

            }
        });
    } else {
        facebookLogOut();
    }
}

function facebookLogOut() {
    FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
            FB.logout(function (response) { });
        }
    });
}

